import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import SEO from "../components/SEO";
import {
  CommonColorButton,
  TitleSection,
  TitleSubText,
} from "../components/common";
import tick from "../images/tick.svg";

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(230px, 275px));
  gap: 30px;
  justify-content: center;
  padding: 0 30px 3em;
  margin-bottom: 100px;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(230px, 275px));
  }
`;
const Card = styled.div`
  display: grid;
  align-content: space-between;
  gap: 2em;
  padding: 30px;
  border: 1px solid #ffffff;
  border: ${({ yearly }) => yearly && `1px solid #635BFF`};
  border-radius: 10px;
  box-shadow: 0 20px 40px #94a6ce29;
  background-color: #fff;
  &:hover {
    border-color: ${({ hoverBorder }) => hoverBorder && hoverBorder};
  }
`;
const CardTitle = styled.h4`
  color: ${({ color }) => color || `#635BFF`};
  font-size: 600;
  text-align: center;
  margin-bottom: 0%;
`;
const PricingSection = styled.div`
  padding: 20px;
  background-color: ${({ bgColor }) => bgColor || `#635BFF`};
  border-radius: 10px;
  margin: 0 -15px;
  text-align: center;
`;
const CardPricingTitle = styled.p`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #ffffff;
`;
const CardPricingText = styled.p`
  color: #fff;
  font-size: 11px;
`;
const CardFeatureTextSection = styled.div`
  display: grid;
  grid-template-columns: 22px 1fr;
  gap: 15px 10px;
  align-items: center;
`;
const TickCircle = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #b9c3e6;
  padding: 4px;
`;
const FeatureText = styled.p`
  font-size: 14px;
  @media (max-width: 575px) {
    font-size: 13px;
  }
`;
const CommonAnchor = styled.a`
  text-decoration: none;
`;

const Pricing = ({ location }) => {
  const TickCircleComp = () => (
    <TickCircle>
      <img src={tick} alt="tick" width="100%" />
    </TickCircle>
  );

  return (
    <>
      <SEO
        title="Pricing | Receiptmakerly"
        description="Generate receipts of various types with cost effective plans of Receiptmakerly. Generate, download and send receipts."
        keywords="Receiptmakerly, receipt maker, receipt generator, online receipt maker, online receipt generator, cost of receipt maker, cost of receipt generator"
        pathname={location.pathname && location.pathname}
        sofwareApp
      />
      <TitleSection>
        <h1>Choose the best plan for you!</h1>
      </TitleSection>

      <CardContainer>
        {/* <Card hoverBorder="#729EB2">
          <CardTitle color="#729EB2">Quarterly</CardTitle>
          <PricingSection bgColor="#729EB2">
            <CardPricingTitle>$ 17.00</CardPricingTitle>
            <CardPricingText>Recurring Plan. Cancel Anytime</CardPricingText>
          </PricingSection>
          <CardFeatureTextSection>
            <TickCircleComp />
            <FeatureText>Custom Receipts</FeatureText>
            <TickCircleComp />
            <FeatureText>All Receipt Styles</FeatureText>
            <TickCircleComp />
            <FeatureText>Custom Fonts</FeatureText>
            <TickCircleComp />
            <FeatureText>No Watermark</FeatureText>
          </CardFeatureTextSection>
          <CommonAnchor
            target="_blank"
            rel="noopener noreferrer"
            href="https://app.receiptmakerly.com/auth/register?plan=weekly"
          >
            <CommonColorButton
              bgColor="#729EB2"
              radius="6px"
              boxShadow="none"
              hoverBg="#5F8B9F"
            >
              Choose Plan
            </CommonColorButton>
          </CommonAnchor>
        </Card> */}

        <Card hoverBorder="#0DC3E8">
          <CardTitle color="#0DC3E8">Monthly</CardTitle>
          <PricingSection bgColor="#0DC3E8">
            <CardPricingTitle>$ 8.90</CardPricingTitle>
            <CardPricingText>Recurring Plan. Cancel Anytime</CardPricingText>
          </PricingSection>
          <CardFeatureTextSection>
            <TickCircleComp />
            <FeatureText>Custom Receipts</FeatureText>
            <TickCircleComp />
            <FeatureText>All Receipt Styles</FeatureText>
            <TickCircleComp />
            <FeatureText>Custom Fonts</FeatureText>
            <TickCircleComp />
            <FeatureText>No Watermark</FeatureText>
          </CardFeatureTextSection>
          <CommonAnchor
            target="_blank"
            rel="noopener noreferrer"
            href="https://app.receiptmakerly.com/auth/register?plan=monthly"
          >
            <CommonColorButton
              bgColor="#0DC3E8"
              radius="6px"
              boxShadow="none"
              hoverBg="#00CAF4"
            >
              Choose Plan
            </CommonColorButton>
          </CommonAnchor>
        </Card>

        <Card hoverBorder="#635BFF">
          <CardTitle>Yearly</CardTitle>
          <PricingSection>
            <CardPricingTitle>$ 47.00</CardPricingTitle>
            <CardPricingText>Recurring Plan. Cancel Anytime</CardPricingText>
          </PricingSection>
          <CardFeatureTextSection>
            <TickCircleComp />
            <FeatureText>Custom Receipts</FeatureText>
            <TickCircleComp />
            <FeatureText>All Receipt Styles</FeatureText>
            <TickCircleComp />
            <FeatureText>Custom Fonts</FeatureText>
            <TickCircleComp />
            <FeatureText>No Watermark</FeatureText>
          </CardFeatureTextSection>
          <CommonAnchor
            target="_blank"
            rel="noopener noreferrer"
            href="https://app.receiptmakerly.com/auth/register?plan=yearly"
          >
            <CommonColorButton bgColor="#635BFF" radius="6px" boxShadow="none">
              Choose Plan
            </CommonColorButton>
          </CommonAnchor>
        </Card>
      </CardContainer>
    </>
  );
};

export default Pricing;
